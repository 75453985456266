.main_container {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
}

.left_container {
  width: 300px;
}

.status_bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.table_container {
  width: 1560px;
}

.status_bar_btn {
  margin: 0 4px;
}

.graph_container {
  position: absolute;
  width: 50000px;
  height: 50000px;
  transform-origin: 0 0;
}

.svg_graph {
  position: absolute;
  width: 50000px;
  height: 50000px;
  z-index: 100;
}

.blue_line {
  //width: 20px;
  //height: 2px;
  //background-color: cornflowerblue;
  //border-color: cornflowerblue;
  //border-width: 1px;
  //border-radius: 5px;
  position: fixed;
  padding: 0;
  background-color: cornflowerblue;
  box-sizing: border-box;
  z-index: 0;
}

.horizontal {
  height: 3px;
}

.nav_tray_container {
  background: rgb(255, 255, 255);
  border-color: black;
  border-width: 1px;
  border-radius: 8px;
  //width: 240px;
  //height: 40px;
  padding: 4px 8px;
  margin: 10px 0;
  cursor: default;
  //display: flex;
  //flex-direction: column;
}

.nav_grab {
  position: absolute;
  background: rgb(255, 255, 255);
  opacity: 0.7;
  cursor: grabbing;
  padding: 4px 8px;
  border-color: black;
  border-width: 1px;
  border-radius: 8px;
  z-index: 200;
  pointer-events: none;
}

.trigger_form_control {
  margin-top: 20px;
  margin-bottom: 20px;
}

.trigger_status_active {
  cursor: pointer;
}

.trigger_status_inactive {
  color: #c4c4c4;
}

.tray_type_title {
  color: #8c8989;
  font-size: 12px;
}

.config_form_control {
  margin-bottom: 14px;
}

.subform-group {
  padding: 10px 10px 10px 30px;
  background-color: #f1f1f1;
  border-width: 1px;
  border-color: #e0e0e0;
  border-radius: 5px;
}

.subform-control {
  display: flex;
  align-items: center;
}

.header_container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
  background-color: #fff;
}

.tray_stroke_black {
  stroke: black;
  stroke-width: 1px;
}

.tray_stroke_blue {
  stroke: #006eff;
  stroke-width: 3px;
}
